import Axios, { AxiosError, AxiosResponse } from "axios";
import { EventEmitter } from "events";

// Endpoints
export enum Endpoint {
  RECEIPT = "receipt",
  RECEIPT_UUID = "receipt/receiptWelcome",
  GPS_COORDINATES = "gps/gpsCoordinates"
}

export enum ApiType {
  Rest
}

// const process = {
//   env: {
//     REACT_APP_API_URL: "http://localhost:3000/v1/",
//     REACT_APP_WS_URL: "ws://localhost:3000/"
//   }
// }
let check = false;
class ApiClass extends EventEmitter {
  private readonly axios = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
  });


  constructor() {
    super();

    Axios.interceptors.response.use(
      (response: AxiosResponse) => {
        console.log("INTERCEPTED");
        return response.data;
      },
      (error: AxiosError) => {
        if (
          error.response?.status === undefined ||
          error.response?.status >= 500
        ) {
          console.error("API Server Error", error);
          // Show dialog
          return;
        }
      }
    );

    // if (AuthService.isLoggedIn()) {
    // }
  }

  async get<Response, Params>(
    endpoint: Endpoint,
    params?: Params,
  ): Promise<Response> {
    const response = await this.axios.get(endpoint, { params });
    return response.data;
  }

  async post<Response, Params>(
    endpoint: Endpoint,
    data?: Params,
  ): Promise<Response> {
    const response = await this.axios.post(endpoint, data);
    return response.data;
  }

  async patch<Response, Params>(
    endpoint: Endpoint,
    data?: Params,
  ): Promise<Response> {
    const response = await this.axios.patch(endpoint, data);
    return response.data;
  }

  async delete<Response, Params>(
    endpoint: Endpoint,
    data?: Params,
  ): Promise<Response> {
    const response = await this.axios.delete(endpoint);
    return response.data;
  }
}

export const Api = new ApiClass();
